import VueI18n from "vue-i18n";
import Vue from 'vue';
import settings from '@/settings/settings'

import en from "@/lang/en";
import uy from "@/lang/uy";
import es from "@/lang/es";
import ar from "@/lang/ar";
import bnf from "@/lang/bnf";
import mx from "@/lang/mx";
import mx_mitras from "@/lang/mx_mitras";

Vue.use(VueI18n);
export const languge_list = [
    {flag: 'us', language: 'en', title: 'English'},
    {flag: 'uy', language: 'uy', title: 'Español'},
    {flag: 'es', language: 'es', title: 'Español'},
    {flag: 'ar', language: 'ar', title: 'Español'},
    {flag: 'bnf', language: 'bnf', title: 'Español'},
    {flag: 'mx', language: 'mx', title: 'Español'},
    {flag: 'mx_mitras', language: 'mx_mitras', title: 'Español'}
];

const messages = {
    uy: uy,
    es: es,
    en: en,
    ar: ar,
    bnf: bnf,
    mx: mx,
    mx_mitras: mx_mitras,
};
const i18n = new VueI18n({
    // set locale
    locale: settings.default_lang,
    // set fallback locale
    fallbackLocale: 'uy',
    // set locale messages
    messages,
});
export default i18n;

